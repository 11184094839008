<template>
  <b-card
  >
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
        v-if="allowCreate()"
          @click.prevent="add"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal
        v-if="allowCreate()"
          v-model="showModalTambah"
          id="modal-tambah"
          cancel-variant="secondary"
          ok-title="Tambah"
          cancel-title="Batal"
          centered
          title="Form Tambah"
        >
          <b-form>
            <b-form-group>
              <label for="jabatan">Jabatan:</label>
              <b-form-input v-model="nama_jabatan" id="jabatan" type="text" placeholder="Nama Jabatan" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="primary" @click="submit">
                Tambah
              </b-button>
              <b-button size="sm" variant="danger" @click="resetForm">
                Batal
              </b-button>
            </section>
          </template>
        </b-modal>
        <b-modal
        v-if="allowUpdate()"
          v-model="showModalEdit"
          id="modal-ubah"
          cancel-variant="secondary"
          ok-title="Ubah"
          cancel-title="Batal"
          centered
          title="Form Ubah"
        >
          <b-form>
            <b-form-group>
              <label for="jabatan">Jabatan:</label>
              <b-form-input v-model="nama_jabatan" id="jabatan" type="text" placeholder="Nama Jabatan" />
            </b-form-group>
          </b-form>

          <template #modal-footer>
            <section class="d-flex justify-content-end align-items-center">
              <b-button size="sm" class="mr-1" variant="info" @click="submit">
                Ubah
              </b-button>
              <b-button size="sm" variant="danger" @click="resetForm">
                Batal
              </b-button>
            </section>
          </template>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group
          :label="$t('Per page')"
          label-size="sm"
          label-for="perPageSelect"
          class="mb-0"
        >
          <!-- <label class="d-inline-block text-sm-left mr-50">Per page</label> -->
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
              class="w-25"
            >
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                {{ $t('Clear') }}
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(no)="row">
            <strong class="text-center">
              {{ (row.index + 1) }}
            </strong>
          </template>
          <template #cell(actions)="row">
            <b-button
            v-if="allowUpdate()"
            v-b-tooltip.hover.right="'Ubah'"
              size="sm"
              @click="edit(row.item)"
              class="mr-1"
              variant="outline-info"
            >
            <feather-icon icon="EditIcon"/>
            </b-button>
            <b-button
            v-if="allowDelete()"
            v-b-tooltip.hover.right="'Hapus'"
              size="sm"
              @click="remove(row.item)"
              class="mr-1"
              variant="outline-danger"
            >
            <feather-icon icon="TrashIcon"/>
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable,VBTooltip,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,BForm,BCard,
} from "bootstrap-vue";

export default {
  components: {
    BCardActions,VBTooltip,ToastificationContent,BCard,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,BForm,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      showModalTambah: false,
      showModalEdit: false,
      id: null,
      nama_jabatan: null,
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "no",
          label: "No",
        },
        { key: "nama_jabatan", label: "Jabatan", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    
    this.getData()

  },
  methods: {
    add() {
      this.id = null
      this.activeAction = 'tambah'
      this.showModalTambah = true
      this.showModalEdit = false
    },
    remove(item ){
      this.$swal({
        title: 'Anda yakin?',
        text: `Data Jabatan "${item.nama_jabatan}" ini akan dihapus`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Batal',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          item.fungsi = 1 // soft delete
          this.$store.dispatch('jabatan/save', [item])
          .then(() => {
            this.pesanBerhasilHapus()
            this.resetForm()
            this.getData()
          })
          .catch(e => {
            this.pesanGagalHapus()
            this.displayError(e)
            return false
          })
        }
      })
    },
    resetForm() {
      this.id = null
      this.nama_jabatan = null
      this.showModalTambah = false
      this.showModalEdit = false
    },
    submit() {
      if(this.nama_jabatan == null || this.nama_jabatan == '') {
        this.pesanGagalSimpan()
        return false
      }
      let payload = {
        nama_jabatan: this.nama_jabatan
      }

      if(this.id) {
        payload.id = this.id
      }

      this.$store.dispatch('jabatan/save', [payload])
      .then(() => {
        if(this.activeAction == 'tambah') {
          this.id = null
        }
        this.nama_jabatan = null
        this.showModalTambah = false
          this.showModalEdit = false
          this.getData()
          this.pesanBerhasilSimpan()
      })
      .catch(e => console.error(e))
    },
    edit(item) {
      let { id, nama_jabatan } = item
      this.id = id
      this.nama_jabatan = nama_jabatan
      this.showModalEdit = true
    },
    pesanBerhasilSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menyimpan data',
          variant: 'success',
        },
      },
      {
        position:'bottom-right',
      })
    },
    pesanGagalSimpan() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menyimpan data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
      {
        position:'bottom-right',
      })
    },
    pesanBerhasilHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Berhasil menghapus data',
          variant: 'success',
        },
      },
      {
        position:'bottom-right',
      })
    },
    pesanGagalHapus() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Notifikasi',
          icon: 'CheckIcon',
          text: 'Gagal menghapus data, harap cek kembali data yang diisi',
          variant: 'warning',
        },
      },
      {
        position:'bottom-right',
      })
    },
    getData() {
      this.$store.dispatch('jabatan/getData', {})
      .then(() => {
        this.items = this.$store.state.jabatan.datas
        this.totalRows = this.items.length;
      })
      .catch(e => console.error(e))
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>
